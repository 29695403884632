// import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

var firebaseConfig = {
  apiKey: 'AIzaSyCrNE47G_-3CNcJ7JUurf8UVL8y64ye4Ac',
  authDomain: 'reallifeapplication-66ce4.firebaseapp.com',
  projectId: 'reallifeapplication-66ce4',
  storageBucket: 'reallifeapplication-66ce4.appspot.com',
  messagingSenderId: '45933455202',
  appId: '1:45933455202:web:9e57a8928fc6c4422cae3b',
  measurementId: 'G-PTZDNNSV5B',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
