import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { CardType } from '../types/cardtype';

export const Cardd = (props: CardType) => {
  return (
    <div className='cardd'>
      <Accordion>
        <Accordion.Item eventKey='0'>
          <Accordion.Header className='infoplustitle'>
            {props.list1.title}
          </Accordion.Header>
          <Accordion.Body>
            <p>
              {props.list1.fact1}{' '}
              <a
                href={props.list1.link1}
                target='_blank'
                rel='noopener noreferrer'
              >
                link
              </a>
            </p>
            <p>
              {props.list1.fact2}{' '}
              <a
                href={props.list1.link2}
                target='_blank'
                rel='noopener noreferrer'
              >
                link
              </a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
