import React from 'react';
import { Spinner } from 'react-bootstrap';

export const Loading = () => {
  return (
    <div className='loading'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'></div>
          <div className='col'>
            <Spinner animation='border' role='status'>
              <span className='sr-only'></span>
            </Spinner>
          </div>
          <div className='col'></div>
        </div>
      </div>
    </div>
  );
};
