import React from 'react';
import { Cardd } from './card';
import { InfoPlusCardPropType } from '../types/infopluscard';

export const InfoPlusCard = (props: InfoPlusCardPropType) => {
  return props.count === 1 ? (
    <div>
      <p className='character'>{props.character}</p>
      <Cardd key={props.id} id={props.id} list1={props.list} />
    </div>
  ) : (
    <div>
      <Cardd key={props.id} id={props.id} list1={props.list} />
    </div>
  );
};
