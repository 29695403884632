import React, { useState, useEffect } from 'react';
import { db } from '../firebase/Firebase';
import { Loading } from './loading';
import { InfoPlusCard } from './infopluscard';
import { InfoPlusCardType } from '../types/infopluscard';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
const Content = () => {
  const [lists, setLists] = useState<InfoPlusCardType[]>([]);
  var count = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ];
  useEffect(() => {
    const q = query(collection(db, 'reallist'), orderBy('title'));

    onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title,
        fact1: doc.data().fact1,
        link1: doc.data().link1,
        fact2: doc.data().fact2,
        link2: doc.data().link2,
      }));
      setLists(data);
    });
  }, []);
  return (
    <div>
      {lists.length === 0 ? (
        <Loading />
      ) : (
        lists.map((list: InfoPlusCardType) => {
          const firstLetter = list.title.slice(0, 1).toUpperCase();

          if (firstLetter >= 'A' && firstLetter <= 'Z') {
            count[firstLetter.charCodeAt(0) - 'A'.charCodeAt(0)]++;
            return (
              <InfoPlusCard
                key={list.id}
                count={count[firstLetter.charCodeAt(0) - 'A'.charCodeAt(0)]}
                character={firstLetter}
                id={list.id}
                list={list}
              />
            );
          } else {
            return null;
          }
        })
      )}
    </div>
  );
};

export default Content;
