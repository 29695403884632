import React from 'react';
import type from '../assets/images/type.png';
import Content from '../components/content';

export const Home = () => {
  return (
    <div>
      <div className='home container-fluid'>
        <div className='row'>
          <img src={type} alt='' width='100%' />
        </div>
        <div className='row'>
          <div className='col'></div>
          <div className='col-7'>
            <p className='description'>
              If we study computer terms when we read every theory out there
              related to that term,it is almost everytime one thing is missing
              that where it is PRACTICALLY applied.So without futher ado with no
              useless detail{' '}
            </p>
          </div>
          <div className='col'></div>
        </div>
        <div className='row'>
          <div className='col'></div>
          <div className='col-7'>
            {' '}
            <Content />{' '}
          </div>
          <div className='col'></div>
        </div>
      </div>
    </div>
  );
};
