import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Home } from './pages/home';
import { InputInfo } from './pages/inputinfo';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/7218945921' element={<InputInfo />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
